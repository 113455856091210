<div
	class="relative w-full mx-auto overflow-hidden rounded-lg shadow-lg md:grid md:grid-cols-5 sm:max-w-lg md:max-w-2xl lg:max-w-3xl"
>
	<div class="hidden md:block md:col-span-2 bg-blue-50" id="bg-sign-overlay"></div>
	<div class="px-6 py-10 bg-white md:col-span-3 sm:p-14">
		<form class="grid gap-6" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
			<div class="flex items-center justify-between mb-4">
				<h2 class="text-2xl font-bold">Welcome Back!</h2>
				<!-- close sign in modal -->
				<uc-close-modal></uc-close-modal>
			</div>

			<!-- email -->
			<uc-input-field
				formControlName="loginEmail"
				inputType="email"
				controlName="loginEmail"
				placeholder="Email Address"
				autocomplete="email"
				labelText="Email Address"
				[formSubmitted]="formSubmitted"
			>
			</uc-input-field>

			<!-- password -->
			<div>
				<uc-password-field
					type="input"
					idAttr="password"
					autoComplete="new-password"
					labelName="Password"
					formControlName="password"
					controlName="password"
					[formSubmitted]="formSubmitted"
				>
				</uc-password-field>

				<!-- error from backend -->
				@if (formSubmitted && formError()) {
					<uc-form-error
						fieldError="Incorrect email or password"
						[formError]="formError()"
					>
					</uc-form-error>
				}

				<div class="flex justify-end mt-1" (click)="closeModalPanel()">
					<a routerLink="/password" class="text-xs blue-link"
						>Forgot password?</a
					>
				</div>
			</div>
			<div class="">
				<button
					class="flex justify-center w-full mx-auto text-base border rounded-lg blue-button"
				>
					{{ isLoading ? 'Loading...' : 'Sign In' }}
				</button>
				<div class="flex justify-center mt-4">
					<p class="text-sm text-gray-500">
						Don't have an account?
						@if (redirectToRegisterPage) {
							<a
								routerLink="/register"
								(click)="closeModalPanel()"
								class="text-sm blue-link"
								>Register now</a
							>
						} @else {
							<button
								type="button"
								(click)="closeModalPanel()"
								class="text-sm blue-link"
							>
								Register now
							</button>
						}
					</p>
				</div>
			</div>
		</form>
	</div>
</div>
